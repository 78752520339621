import React from "react"
import Layout from "../components/layout/layout"
import LienTitleRequest from "../components/lienTitleRequest/lienTitleRequest";

export default function RequestTitle() {
  return (
    <Layout>
      <LienTitleRequest />
    </Layout>
  );
}
