import React from 'react'
import { useSiteConfig } from '../layout/getSiteConfig'
import PaylessFormBase from '../paylessFormBase/paylessFormBase'

export default function LienTitleRequest() {
  const { lienTitleRequestInfoApi } = useSiteConfig()

  return (
    <PaylessFormBase {...{
      formName: 'Lien Holder Title Request',
      formFile: '/lien-title-request.pdf',
      formPath: 'lien-title-request',
      infoApi: lienTitleRequestInfoApi,
      description: 'To request a lien holder title, please use this form with a copy of your Driver’s License.'
    }} />
  )
}